.button {
	background: #2185D0;
	padding: 9px 17px;
	border-radius: 8px;
	display: inline-block;
	text-decoration: none;
	text-align: center;
    font-family: 'Plus Jakarta Sans';
    font-weight: 600;
	font-size: 17px;
	color: #FFFFFF;
    border: 0;
    outline: 0;
    transition: all .2s;
    cursor: pointer;
    box-shadow: 0 0 0 0px rgb(33 133 208 / 38%);

    &:hover {
        transform: scale(1.03);
    }
    &:focus-visible {
        transform: scale(1.03);
        background: darken(#2185D0, 10%);
        box-shadow: 0 0 0 4px rgb(33 133 208 / 38%);
    }
    &:active {
        transform: scale(1.06);
        box-shadow: 0 0 0 4px rgb(33 133 208 / 38%);
    }

    &--light {
        background: #E0E1E2;
        color: rgba(0, 0, 0, 0.6);
        box-shadow: 0 0 0 0px rgba(199, 199, 199, 0.4);

        &:focus-visible {
            transform: scale(1.03);
            background: darken(#E0E1E2, 10%);
            box-shadow: 0 0 0 4px rgba(199, 199, 199, 0.4);
        }
        &:active {
            box-shadow: 0 0 0 4px rgba(199, 199, 199, 0.4);
        }
    }
}