@font-face {
    font-family: 'Plus Jakarta Sans';
    src: url('/assets/fonts/PlusJakartaSans-ExtraBold.woff2') format('woff2'), url('/assets/fonts/PlusJakartaSans-ExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Plus Jakarta Sans';
    src: url('/assets/fonts/PlusJakartaSans-ExtraLight.woff2') format('woff2'), url('/assets/fonts/PlusJakartaSans-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Plus Jakarta Sans';
    src: url('/assets/fonts/PlusJakartaSans-BoldItalic.woff2') format('woff2'), url('/assets/fonts/PlusJakartaSans-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Plus Jakarta Sans';
    src: url('/assets/fonts/PlusJakartaSans-LightItalic.woff2') format('woff2'), url('/assets/fonts/PlusJakartaSans-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Plus Jakarta Sans';
    src: url('/assets/fonts/PlusJakartaSans-ExtraLightItalic.woff2') format('woff2'), url('/assets/fonts/PlusJakartaSans-ExtraLightItalic.woff') format('woff');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Plus Jakarta Sans';
    src: url('/assets/fonts/PlusJakartaSans-MediumItalic.woff2') format('woff2'), url('/assets/fonts/PlusJakartaSans-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Plus Jakarta Sans';
    src: url('/assets/fonts/PlusJakartaSans-ExtraBoldItalic.woff2') format('woff2'), url('/assets/fonts/PlusJakartaSans-ExtraBoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Plus Jakarta Sans';
    src: url('/assets/fonts/PlusJakartaSans-Italic.woff2') format('woff2'), url('/assets/fonts/PlusJakartaSans-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Plus Jakarta Sans';
    src: url('/assets/fonts/PlusJakartaSans-Bold.woff2') format('woff2'), url('/assets/fonts/PlusJakartaSans-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Plus Jakarta Sans';
    src: url('/assets/fonts/PlusJakartaSans-Medium.woff2') format('woff2'), url('/assets/fonts/PlusJakartaSans-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Plus Jakarta Sans';
    src: url('/assets/fonts/PlusJakartaSans-Light.woff2') format('woff2'), url('/assets/fonts/PlusJakartaSans-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Plus Jakarta Sans';
    src: url('/assets/fonts/PlusJakartaSans-SemiBold.woff2') format('woff2'), url('/assets/fonts/PlusJakartaSans-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Plus Jakarta Sans';
    src: url('/assets/fonts/PlusJakartaSans-SemiBoldItalic.woff2') format('woff2'), url('/assets/fonts/PlusJakartaSans-SemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Plus Jakarta Sans';
    src: url('/assets/fonts/PlusJakartaSans-Regular.woff2') format('woff2'), url('/assets/fonts/PlusJakartaSans-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}