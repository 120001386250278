.home-section {
    width: 100%;
    height: calc(100vh - 105px);
    background-image: url('../../assets/team.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    position: relative;
    min-height: 750px;

    &--small {
        min-height: 400px;
        height: 400px;
    }

    @media (max-width:1240px) {
        height: 100vh;
    }
    @media (max-width:768px) {
        background-attachment: unset;
    }

    &-backdrop {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgb(0 18 46 / 65%);
        padding: 0 calc(50% - 600px);

        @media (max-width:1300px) { 
            padding: 0 10px;
        }

        &-content {
            color: white;
            width: 450px;
            position: absolute;
            top: calc(50% + 25px);
            transform: translateY(-50%);
            max-width: calc(100% - 20px);

            &--centered {
                left: 50%;
                transform: translate(-50%, -50%);
                text-align: center;
            }

            h3 {
                font-weight: bold;
                font-size: 20px;
                line-height: 24px;
                letter-spacing: 0.15em;
                text-transform: uppercase;
                color: #b4c6d4;
            }
            h1 {
                font-weight: 800;
                font-size: 50px;
                line-height: 61px;
                color: white;
                margin: 12px 0;
            }
            p {
                font-weight: 500;
                font-size: 21px;
                line-height: 28px;
                color: white;
                margin: 13px 0 13px 0;
            }

            .button {
                margin-top: 10px;
                margin-right: 10px;
            }

            @media (max-width:1240px) {
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                text-align: center;
            }

        }

        img {
            max-height: calc(100% - 100px - 75px);
            max-width: calc(100% - ((50% - 600px) * 2) - 550px);
            position: absolute;
            top: calc(50% + 25px);
            right: calc(50% - 600px);
            transform: translateY(-50%);
            object-fit: contain;
            object-position: right center;
            border-radius: 9px;
            box-shadow: 0 11px 21px #0328484b;

            @media (max-width:1240px) {
                display: none;
            }
        }
    }
}

.home-try-section {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    padding-top: 50px;
    margin-bottom: 50px;
    background: #dde8ec;
	margin-top: 0;
	box-shadow: inset 0 -40px 16px -40px #acbbc03b;

    @media (max-width:1000px) {
        display: none;
    }
    @media (max-width:1450px) {
        padding-bottom: 50px;
    }

    &-image {
        width: 50%;
        height: 600px;
        background-position: top left;
        background-size: cover;
        margin-left: 25px;
        background-repeat: no-repeat;
        box-shadow: inset 0 -40px 16px -40px #acbbc03b;

        @media (max-width:1450px) {
            box-shadow: none;
            background-image: url('../../assets/home.png') !important;
            background-position: center left;
        }
    }
    &-content {
        width: 50%;
        padding-left: calc(50% - 800px);
        padding-right: 25px;

        h1 {
            font-size: 28px;
            margin-bottom: 12px;
            display: flex;
            align-items: center;

            span {
                font-size: 14px;
                display: inline-block;
                padding: 3px 11px;
                background: #2c85d06b;
                border-radius: 100px;
                //font-family: 'Inter', sans-serif;
                letter-spacing: 1px;
                color: #103f66;
                margin-left: 10px;
            }
        }
        p {
            font-size: 19px;
            line-height: 28px;
            margin-bottom: 15px;
        }

        .button {
            margin-right: 5px;
        }

        @media (max-width: 1700px) {
            padding-left: 40px;
        }
    }
}

.home-2-section {
    width: 100%;
    padding: 50px calc(50% - 600px);
    display: none;

    @media (max-width:1000px) {
        display: block;
    }

    @media (max-width:1300px) {
        padding: 50px 10px;
    }

    &-card {
        width: 100%;
        text-align: center;
        background: #FFFFFF;
        border: 1px solid #EAEAEA;
        box-shadow: 0px 9px 20px rgb(166 192 211 / 10%);
        box-sizing: border-box;
        border-radius: 15px;
        padding: 30px 15px;

        h1 {
            font-size: 28px;
        }
        p {
            font-size: 19px;
            margin: 15px 0;
        }
        .button {
            margin: 0 5px;
        }
    }
}

.features-section {
    width: 100%;
    padding: 50px calc(50% - 600px);
    text-align: center;

    @media (max-width:1300px) {
        padding: 50px 10px;
    }

    &-grid {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 40px;
        grid-row-gap: 40px;
        margin-bottom: 30px;

        @media (max-width:1000px) {
            grid-template-columns: repeat(1, 1fr);
            grid-template-rows: repeat(4, 1fr);
            grid-row-gap: 20px;
        }

        &-item {
            background: #FFFFFF;
            border: 1px solid #EAEAEA;
            box-shadow: 0px 9px 20px rgb(166 192 211 / 10%);
            border-radius: 10px;
            padding: 25px;
            text-align: left;

            h1 {
                margin-bottom: 12px;
                font-size: 28px;
            }
            p {
                font-size: 19px;
                line-height: 28px;
            }
        }
    }
}

.benefits-section {
    width: 100%;
    padding: 50px calc(50% - 600px);
    margin-top: 50px;

    @media (max-width:1300px) {
        padding: 50px 10px;
    }
    @media (max-width:1000px) {
        margin-top: 25px;
    }

    .benefits-item {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #FFFFFF;
        border: 1px solid #EAEAEA;
        box-shadow: 0px 9px 20px rgb(166 192 211 / 10%);
        border-radius: 10px;
        padding: 25px;
        margin: 15px 0;

        &-content {
            h1 {
                margin-bottom: 12px;
                font-size: 28px;
            }
            p {
                font-size: 19px;
            	line-height: 28px;
            }
        }
        &-image {
            min-width: 200px;

            @media (max-width:1000px) {
                min-width: 200px;
            }
            @media (max-width:800px) {
                display: none;
            }
            img {
                height: 150px;
                width: 150px;
                object-fit: contain;

                @media (max-width:1000px) {
                    height: 150px;
                    width: 150px;
                }
            }
        }
    }
}

.contact-section {
    width: 100%;
    padding: 50px calc(50% - 600px);

    @media (max-width:1300px) {
        padding: 50px 10px;
    }

    &-card {
        width: 100%;
        text-align: center;
        background: #FFFFFF;
        border: 1px solid #EAEAEA;
        box-shadow: 0px 9px 20px rgb(166 192 211 / 10%);
        box-sizing: border-box;
        border-radius: 15px;
        padding: 30px 15px;

        h1 {
            font-size: 28px;
        }
        p {
            font-size: 19px;
            margin: 15px 0;
        }
        .button {
            margin: 0 5px;
        }
    }
}