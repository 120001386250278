.error-404 {
    display: flex;
	align-items: center;
	justify-content: center;
    flex-direction: column;
    padding: 0 10px;
	height: 400px;
    text-align: center;

    img {
        width: 48px;
        height: 48px;
        object-fit: contain;
        margin-bottom: 30px;
    }
    h1 {
        font-size: 32px;
        color: #1d3a4c;
        margin-bottom: 20px;
    }
}