.modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgb(0 18 46 / 50%);
	z-index: 10;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 20px;

    @media (max-width:850px) {
        align-items: flex-start;
    }

    &-content {
        padding: 20px;
        background: #FFFFFF;
        border: 1px solid #a3b8ce;
        box-shadow: 0px 8px 14px #00000021;
        border-radius: 10px;
        width: 560px;
        font-size: 18px;

        @media (max-width:850px) {
            width: 100%;
        }
    }
    &-header {
        width: 100%;
        height: 40px;
        border-bottom: 1px solid #d3dbe1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;

        &-title {
            font-size: 22px;
            font-weight: bold;
            color: #192e42;
        }
        &-close {
            height: 30px;
            width: 30px;
            border-radius: 100px;
            background: #f7f7f7;
            border: none;
            cursor: pointer;
            text-align: center;
            transition: all .2s;

            svg {
                margin: 3px;
            	stroke: #5c6e78;
            }

            &:focus-visible,
            &:hover {
                background: rgb(216, 216, 216);
            }
        }
    }
}
