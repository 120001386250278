.features {
    &-item {
        width: 100%;
        background: white;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 50px calc(50% - 800px);

        &-image {
            width: 50%;
            text-align: right;
            padding-right: 25px;

            img {
                height: 450px;
                object-fit: contain;
            }
        }
        &-content {
            width: 50%;
            padding-left: 25px;

            h1 {
                font-size: 28px;
                margin-bottom: 12px;
                display: flex;
                align-items: center;

                span {
                    font-size: 14px;
                    display: inline-block;
                    padding: 3px 11px;
                    background: #2c85d06b;
                    border-radius: 100px;
                    //font-family: 'Inter', sans-serif;
                    letter-spacing: 1px;
                    color: #103f66;
                    margin-left: 10px;
                }
            }
            p {
                font-size: 19px;
                line-height: 28px;
            }
        }

        &--right {
            .features-item-image {
                padding-right: 0;
                padding-left: 25px;
                text-align: left;
                background-repeat: no-repeat;
            }
            .features-item-content {
                padding-left: 0;
                padding-right: 25px;
            }
        }

        // --------------------------------------------------

        &--company-wide-posts {
            padding: 0;
            padding-top: 100px;
            background: #f0f5f7;

            @media (max-width: 1700px) {
                padding-top: 50px;
            }

            @media (max-width: 1000px) {
                padding: 50px 20px;
            }

            .features-item-image {
                height: 600px;
                background-position: top right;
                background-size: calc(100% + 70px);
                margin-right: 25px;
                background-repeat: no-repeat;

                @media (max-width: 1700px) {
                    background-position: center right;
                }
                @media (max-width: 1000px) {
                    display: none;
                }
            }
            .features-item-content {
                padding-right: calc(50% - 800px);

                @media (max-width: 1700px) {
                    padding-right: 40px;
                }
                @media (max-width: 1000px) {
                    padding: 0;
                    width: 100%;
                }
            }
        }

        &--groups {
            padding: 50px 0;
            @media (max-width: 1000px) {
                padding: 50px 20px;
            }

            .features-item-image {
                height: 600px;
                background-position: left center;
                background-size: contain;
                margin-left: 50px;
                background-repeat: no-repeat;
                margin-right: 25px;

                @media (max-width: 1000px) {
                    display: none;
                }
            }
            .features-item-content {
                padding-left: calc(50% - 800px);

                @media (max-width: 1700px) {
                    padding-left: 40px;
                }
                @media (max-width: 1000px) {
                    padding: 0;
                    width: 100%;
                }
            }
        }

        &--knowledge-base {
            padding: 0;
            padding-top: 50px;
            background: #f0f5f7;

            @media (max-width: 1700px) {
                padding: 50px 0;
            }
            @media (max-width: 1000px) {
                padding: 50px 20px;
            }

            .features-item-image {
                height: 600px;
                background-position: top right;
                background-size: calc(100% + 110px);
                margin-right: 25px;
                background-repeat: no-repeat;

                @media (max-width: 1700px) {
                    background-position: center right;
                }
                @media (max-width: 1000px) {
                    display: none;
                }
            }
            .features-item-content {
                padding-right: calc(50% - 800px);

                @media (max-width: 1700px) {
                    padding-right: 40px;
                }
                @media (max-width: 1000px) {
                    padding: 0;
                    width: 100%;
                }
            }
        }


        &--mobile {
            padding: 0;
            padding-top: 50px;
            @media (max-width: 1000px) {
                padding: 50px 20px;
            }

            .features-item-image {
                height: 600px;
                background-position: left bottom;
                background-size: contain;
                margin-left: 75px;
                background-repeat: no-repeat;

                @media (max-width: 1000px) {
                    display: none;
                }
            }
            .features-item-content {
                padding-left: calc(50% - 800px);

                @media (max-width: 1700px) {
                    padding-left: 40px;
                }
                @media (max-width: 1000px) {
                    padding: 0;
                    width: 100%;
                }
            }
        }

        &--coming-soon {
            padding: 0;
            padding-top: 50px;
            @media (max-width: 1000px) {
                padding: 50px 20px;
            }
            background: #f0f5f7;

            @media (max-width: 1700px) {
                padding-top: 50px;
            }

            @media (max-width: 1000px) {
                padding: 50px 20px;
            }

            .features-item-image {
                position: relative;
                height: 600px;
                background-position: top right;
                background-size: calc(100% + 70px);
                margin-right: 25px;
                background-repeat: no-repeat;

                div {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: #f0f5f7b3;
                    backdrop-filter: blur(3px);
                }

                @media (max-width: 1700px) {
                    background-position: center right;
                }
                @media (max-width: 1000px) {
                    display: none;
                }
            }
            .features-item-content {
                padding-right: calc(50% - 800px);

                @media (max-width: 1700px) {
                    padding-right: 40px;
                }
                @media (max-width: 1000px) {
                    padding: 0;
                    width: 100%;
                }
            }
        }
    }
}